import React from "react";
import FunctionPageLayout from "../components/functionPageLayout";
import { graphql } from "gatsby";
import SEO from "../components/seo";
import Lightbox from "../components/lightBox";
import BenefitBlockWrapper from "../components/BenefitBlockWrapper";
import BenefitBlock from "../components/benefitBlock";
import {
  FaClock,
  FaList,
  FaUsers,
  FaPuzzlePiece,
  FaSchool,
  FaPlus,
  FaSlidersH,
  FaLaptop,
  FaCreditCard,
} from "react-icons/fa";
import Container from "../components/container";
import * as styles from "./index.module.css";
import Tile from "../components/Tile";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import heroImage2 from "../images/system-crm-dla-firmy-handlowej-i-uslugowej.jpg";
import Columns from "../components/columns";
import ResponsiveEmbed from "react-responsive-embed";

export const query = graphql`
  {
    rekrutacja: file(relativePath: { eq: "dolaczdonas.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
        copywriter: file(relativePath: { eq: "copywriter-dwa.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

const Copywriter = ({ data }) => {
  return (
    <FunctionPageLayout title="Copywriter / Specjalista ds. content marketingu" headline="Dołącz do naszego zespołu">
      <SEO
        title="Copywriter / Specjalista ds. content marketingu – oferta pracy"
        description="Producent oprogramowania dla firm ✅ Oferta pracy • Copywriter / Specjalista ds. content marketingu • Skontaktuj się z nami"
      />
      <Container>
        <h2 style={{
        paddingTop: 20,
        paddingBottom: 20,}}>Kim jesteśmy?</h2>
        <p>
          Jesteśmy{" "}
          <strong> producentem i dostawcą oprogramowania dla biznesu</strong>{" "}
          działającym od 2002 roku. Współpracujemy zarówno z dużymi, rozpoznawalnymi firmami, 
          jak i z mniejszymi – dynamicznie rozwijającymi się przedsiębiorstwami. Naszych klientów 
          wspieramy biznesowymi narzędziami, które umożliwiają realizację strategii, zapewniając 
          tym samym lepsze i przyjemniejsze jutro. Zespół Questy składa się już z ponad 50 
          specjalistów, których łączy wspólna pasja — technologie informacyjne.
        </p>
        <h2 style={{
        paddingTop: 35,
        paddingBottom: 20,}}>Wartości, które wyznajemy:</h2>
        <p>
          <ul>
            <li>
              {" "}
              stosujemy dobre praktyki zarządzania, a&nbsp;naszym głównym 
              miernikiem wartości są osiągane rezultaty,
            </li>
            <li>promujemy i wspieramy nieustanne samodoskonalenie, rozwój oraz edukację,</li>
            <li>w osiąganiu założonych celów wykorzystujemy zdobyte doświadczenie i wiedzę,</li>
            <li>naszych klientów i pracowników traktujemy jako partnerów, 
            z którymi wspólnie budujemy lepszą przyszłość,</li>
            <li>w naszej codziennej pracy cenimy sobie pasję i&nbsp;zaangażowanie,</li>
            <li>wierzymy w otwartą komunikację – jeśli coś nie działa, 
            mówimy o tym głośno i unikamy nieporozumień,</li>
            <li>nie jesteśmy korporacją – w naszej kulturze organizacyjnej 
            wszyscy się znają i współpracują ze sobą,</li>
            <li>stawiamy na pracę zespołową, bo sukces w&nbsp;naszej 
            branży zależy od zaangażowania wielu osób,</li>
            <li>i… last but not least – bardzo lubimy technologie informatyczne!</li>
          </ul>
        </p>
      </Container>
      <h2 style={{
          paddingBottom: 20,
          paddingTop: 30,}}>
        Tworzymy zgrany zespół</h2>

      <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
            <p style={{
        paddingTop: 20,}}>
              Do codziennych działań motywuje nas przede wszystkim skuteczna 
              współpraca i rewelacyjna atmosfera w zespole.<br />
              Każdego dnia wspólnie tworzymy projekty, rozwiązujemy problemy naszych 
              klientów, a&nbsp;także świętujemy nasze sukcesy – świetnie się przy tym wszystkim bawiąc.
              <br />
              <strong>
                {" "}
                …bo przecież w przyjaznym otoczeniu obowiązki stają się przyjemnością, prawda? :)
              </strong>
            </p>
          </div>
          <div className="text-block">
            <ResponsiveEmbed
              src="https://www.youtube.com/embed/lLKbdB1I0xo"
              allowFullScreen
              ratio="16:9"
            />
          </div>
        </div>
      </Container>
      <h2
        style={{
          paddingTop: 30,
          paddingBottom: 30,
          textAlign: "center",
        }}
      >
        {" "}
        Dlaczego warto z nami pracować?{" "}
      </h2>

      <div>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaClock size="60px" />}
            title="Stabilna firma o ugruntowanej pozycji w&nbsp;czołówce rynku"
          />
          <BenefitBlock
            icon={<FaUsers size="60px" />}
            title="Ponad 50-osobowy, zgrany zespół"
          />
          <BenefitBlock
            icon={<FaPuzzlePiece size="60px" />}
            title="Różnorodność projektów i technologii"
          /></BenefitBlockWrapper>
        <BenefitBlockWrapper>
          <BenefitBlock
            icon={<FaSchool size="60px" />}
            title="Dzielimy się wiedzą i doświadczeniem"
          />
          <BenefitBlock
            icon={<FaSlidersH size="60px" />}
            title="Działamy elastycznie i do każdego podchodzimy indywidualnie"
          />
          <BenefitBlock
            icon={<FaPlus size="60px" />}
            title="Oferujemy wiele dodatkowych benefitów"
          />
        </BenefitBlockWrapper>
      </div>

      <center>
        <h3>Copywriter / Specjalista ds. content marketingu</h3>
        <p>Praca stacjonarna. Po wdrożeniu możliwa praca w&nbsp;modelu hybrydowym.</p>
      </center>
      <p style={{
        paddingTop: 20,}}>
        <strong>
          Twoim celem będzie tworzenie różnego formatu tekstów i contentu zgodnie z SEO 
          w&nbsp;celu budowania wartości marki Questy oraz marek produktowych.</strong></p>
      <p style={{
        paddingTop: 20,}}><strong>Czym będziesz się zajmować?</strong>
      </p>
      <p>
        <ul>
          <li>
            <b>tworzeniem we współpracy z działem marketingu treści edukacyjnych w różnych formatach (np. artykuły na blogu, prezentacje),</b>
          </li>
          <li>
            <b>publikowaniem stworzonych treści na stronach www marek firmy,</b>
          </li>
          <li>obserwowaniem bieżących trendów rynkowych i&nbsp;kreowaniem własnych pomysłów,</li>
          <li>koordynowaniem link buildingu w zakresie pozycjonowania stron firmowych,</li>
          <li>dbaniem o PR i wizerunek firmy w każdej tworzonej treści oraz komunikacie.</li>
        </ul></p>
        <Container className="container container--text">
        <div className="text-answers">
          <div className="text-block">
             <p style={{
        paddingTop: 10,}}>
        <strong>Jakiej osoby szukamy?</strong>
      </p>
      <p><ul><li><b>z “lekkim piórem” – swobodą pisania na różne tematy, w tym z&nbsp;obszaru: 
            technologii i rozwiązań informatycznych, sprzedaży czy Internetu.</b>
          </li>
          <li>
           <b>posiadającej wiedzę w zakresie tworzenia i&nbsp;optymalizowania treści pod kątem SEO,</b>
          </li>
          <li><b>znającej zagadnienie i podstawy link buildingu,</b></li>
          <li>płynnie mówiącej w języku polskim,</li>
          <li>pomysłowej i kreatywnej – gotowej wesprzeć dział marketingu swoimi pomysłami,</li>
          <li>zafascynowanej marketingiem, tworzeniem contentu i reklamą,</li>
          <li>komunikatywnej i pozytywnie nastawionej do zdobywania nowej wiedzy,</li>
          <li>skupionej na zadaniach i dobrze zorganizowanej,</li>
          <li>otwartej na opinie innych w celu poszerzania własnych horyzontów,</li>
          <li>gotowej podjąć pracę w zakresie minimum 3&nbsp;dni w tygodniu.</li>
        </ul></p>
          </div>
          <div className="text-block">
            <GatsbyImage
          image={data.copywriter.childImageSharp.gatsbyImageData}
          style={{
            paddingTop: 40,
            maxWidth: 600,
            margin: "0 auto",
          }}
          alt="Copywriter - grafika"
        />
          </div>
        </div>
      </Container>
     
       <p style={{
        paddingTop: 20,}}>
        <b>Świetnie sprawdzisz się w tej roli, jeśli:</b>
      </p>
      <p style={{
        paddingTop: 20,}}>
        <ul>
          <li>znasz i umiejętnie wykorzystujesz takie narzędzia jak: Google Analytics, GTM (podstawy), Google Search Console, Hotjar, Senuto,</li>
          <li>lubisz dzielić się swoimi pomysłami i chcesz realizować nowe rzeczy,</li>
          <li>znasz zasady języka polskiego i lubisz pisać treści różnego typu,</li>
          <li>lubisz nowoczesną technologię i rozwiązania informatyczne,</li>
          <li>jesteś studentem lub absolwentem kierunków takich jak marketing, copywriting, reklama, biznes lub pokrewnych,</li>
          <li><strong>Dodatkowym atutem </strong>będą również umiejętności graficzne, znajomość Canvy, samodzielne projektowanie grafik czy obróbka zdjęć i zrzutów ekranu.</li>
        </ul>
      </p>
    <h3 style={{
        paddingTop: 20,}}>
        <center>
          <b>Spełniasz wszystkie lub większość z podanych przez nas oczekiwań? Nie zwlekaj – aplikuj!</b>
        </center>
      </h3>
      <p style={{
        paddingTop: 20,}}>
        <strong> Co zyskujesz dołączając do naszego zespołu? </strong></p>
      <p>
        <ul>
          <li><b>Szansę na realizowanie własnych pomysłów</b>, bo w naszej firmie
            stawiamy na innowacyjność wypływającą z zespołu. </li>
          <li>Szansę na poznanie świata IT od kuchni.</li>
          <li>Możliwość udziału w zewnętrznych szkoleniach poszerzających wiedzę z obszaru, którym się zajmujesz.</li>
          <li>Szansę na długoterminową współpracę z elastycznym grafikiem.</li>
          <li>Ciekawą pracę w dynamicznej firmie w zespole ludzi nastawionych na przyszłość i rozwój.</li>
          <li>Wsparcie doświadczonego (i fajnego, a jakże!) zespołu, zainteresowanego grą do jednej bramki.</li>
          <li>Możliwość dalszego rozwoju w kierunku marketingu.</li>
          <li>Premie za wysokie zaangażowanie i wkład w&nbsp;rozwój firmy.</li>
          <li>Przyjazną atmosferę i regularne spotkania integracyjne.</li>
          <li>Pracę w biurze w centrum Poznania (3 min pieszo od Dworca Zachodniego) oraz możliwość 
          pracy hybrydowej (częściowo zdalnej) w&nbsp;przypadku dalszej współpracy.</li>
          <li>Pyszną kawę każdego dnia! 🙂</li>
        </ul>
      </p>

      <Container>
        <h2
          style={{
            textAlign: "center",
            paddingTop: 20,}}>
          Przekonaliśmy Cię?
          <br />
          Sprawdź, jak do nas dołączyć:</h2>
        <br />
        <GatsbyImage
          image={data.rekrutacja.childImageSharp.gatsbyImageData}
          style={{
            maxWidth: 1200,
            margin: "0 auto",
            marginTop: 30,
          }}
          alt="rekrutacja"
        /></Container>
      <h2 style={{
        paddingTop: 40,}}>Czekamy właśnie na Ciebie!</h2>
      <center>
        <p style={{
        paddingTop: 40,}}>
          Wyślij CV z dopiskiem <strong>„Copywriter”</strong> oraz zgodą na
          przetwarzanie danych osobowych w procesie rekrutacji na adres
          <strong> kariera@questy.pl </strong>{" "}
        </p>
          <p>
          <strong>Informujemy, że skontaktujemy się osobiście jedynie z wybranymi
          kandydatami.</strong></p>
      </center>
       <p style={{
        paddingTop: 40,}}><strong>Prosimy o zawarcie w CV następującej klauzuli:</strong></p>
        <p style={{    
fontSize: 13,
lineHeight: "1.7rem",
}}>
        „Wyrażam zgodę na przetwarzanie danych osobowych zawartych w mojej
        ofercie pracy dla potrzeb niezbędnych do realizacji procesu rekrutacji
        prowadzonego przez QUESTY z siedzibą w Poznaniu zgodnie z ustawą z dnia
        29 sierpnia 1997 r. o ochronie danych osobowych (t.j. Dz. U. z 2016 r.,
        poz. 922)”. Jednocześnie wyrażam zgodę na przetwarzanie przez
        ogłoszeniodawcę moich danych osobowych na potrzeby przyszłych
        rekrutacji.”
      </p>
      <p style={{    
fontSize: 14,
lineHeight: "1.7rem",
}}>
        Informujemy, że Administratorem danych jest QUESTY z siedzibą w Poznaniu
        przy ul. Głogowskiej 31/33. Dane zbierane są dla potrzeb rekrutacji. Ma
        Pani/Pan prawo dostępu do treści swoich danych oraz ich poprawiania.
        Podanie danych w zakresie określonym przepisami ustawy z dnia 26 czerwca
        1974 r. Kodeks pracy oraz aktów wykonawczych jest obowiązkowe. Podanie
        dodatkowych danych osobowych jest dobrowolne.
      </p>
    </FunctionPageLayout>
  );
};

export default Copywriter;
